import axios from 'axios';
import { getAccessToken } from "../utils/authUtils"; // Import the utility function
const instance = axios.create();

let requestCount = 0; // Track the number of ongoing requests

// Function to attach the token to the request
const addAuthToken = async (config) => {
  const token = await getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

// Add a request interceptor
instance.interceptors.request.use(config => {
  if (requestCount === 0) {
    showSpinner(); // Show the spinner only for the first request
  }
  requestCount++; // Increment the request count

//Retrieve and attach the token
return new Promise((resolve) => {
  addAuthToken(config).then(resolve); // Handle async token retrieval properly
});


return config;
}, error => {
  requestCount--; // Decrement the request count if there's an error
  if (requestCount === 0) {
    hideSpinner(); // Hide the spinner if no requests are left
  }
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(response => {
  requestCount--; // Decrement the request count when a response is received
  if (requestCount === 0) {
    hideSpinner(); // Hide the spinner if no requests are left
  }
  return response;
}, error => {
  requestCount--; // Decrement the request count if there's an error in the response
  if (requestCount === 0) {
    hideSpinner(); // Hide the spinner if no requests are left
  }
  return Promise.reject(error);
});

// Function to show the spinner
function showSpinner() {
  // You may use the state management library or a global state to manage the loading state
  // For simplicity, we'll use a DOM manipulation here
  const spinner = document.getElementById('global-spinner');
  //console.log("showSpinner",spinner);
  if (spinner) {
    spinner.style.display = 'block';
  }
}

// Function to hide the spinner
function hideSpinner() {
  const spinner = document.getElementById('global-spinner');
 // console.log("hideSpinner",spinner);
  if (spinner) {
    spinner.style.display = 'none';
  }
}

export default instance;