//import { msalInstance } from "../configs/msalConfig"; // Import MSAL instance
import { msalInstance } from "../index";

//import { useMsal, useAccount } from '@azure/msal-react';


export const getAccessToken = async () => {
  
  try {
    const account = msalInstance.getActiveAccount(); // Get the logged-in user
    if (!account) {
      console.warn("No active account found");
      return null;
    }

    const request = {
      scopes: ["api://f30c4038-8285-4c7c-805e-45b63dc8b55d/api.access"], // Replace with your API scope
      account,
    };

    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken || null;
  } catch (error) {
    console.error("Error fetching access token:", error);
    return null;
  }
};